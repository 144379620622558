<template>
  <div>
      <el-row>
          <el-row style="margin-top:10px; margin-left: 50px">
              <el-col :span="24">
                  <el-card style="width: 1175px;height: 80px; text-align: center; margin-bottom: 50px;">
                      <div style="margin-top: 5px">
                          <h2>首页</h2>
                      </div>
                  </el-card>
              </el-col>
          </el-row>
      </el-row>
  </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  name: "Home",
  data(){
    return{

    }
  },
}
</script>

<style>

</style>